import BigNumber from "bignumber.js";

export interface ALV1Props {
	income: number,
	setResult: (a: number) => void
}

const alvPercentage = new BigNumber('0.011');

export const ALV1 = (props: ALV1Props): JSX.Element => {
	const incomePerMonth = new BigNumber(props.income).dividedBy(12);
	const alv1 = incomePerMonth.multipliedBy(alvPercentage);
	props.setResult(alv1.toNumber());
	return (
		<tr>
			<td>ALV1</td>
			<td>{new Intl.NumberFormat('de-CH', { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(alvPercentage.toNumber())}</td>
			<td>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(alv1.toNumber())}</td>
		</tr>
	)
}