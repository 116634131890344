import {useEffect, useState} from "react";
import BigNumber from "bignumber.js";
import {useTranslation} from "react-i18next";
import {CantonTaxes} from "./CantonTaxes";

export interface QuellensteuerProps {
    income: number,
    canton: string,
    isMarried: boolean,
    countKids: number,
    isChurchMember: boolean,
    setResult: (a: number) => void
}

export interface Steuerergebnis {
    betrag: BigNumber,
    prozent: BigNumber,
}

export class Steuersatz {
    steuerbaresEinkommenLower: BigNumber;
    steuerbaresEinkommenUpper: BigNumber;
    steuersatzProzent: BigNumber;
    steuernAbsolut: BigNumber;

    constructor(steuerbaresEinkommen: BigNumber, tarifschritt: BigNumber, steuersatzProzent: BigNumber, steuernAbsolut: BigNumber) {
        this.steuerbaresEinkommenLower = steuerbaresEinkommen;
        this.steuerbaresEinkommenUpper = steuerbaresEinkommen.plus(tarifschritt);
        this.steuersatzProzent = steuersatzProzent;
        this.steuernAbsolut = steuernAbsolut;
    }

    public isWithin(actualIncome: BigNumber): boolean {
        return actualIncome.isGreaterThanOrEqualTo(this.steuerbaresEinkommenLower) && actualIncome.isLessThan(this.steuerbaresEinkommenUpper);
    }

    public getSteuer(income: BigNumber): BigNumber {
        if (this.steuernAbsolut.isZero() && this.steuersatzProzent.isZero()) {
            return new BigNumber(0);
        }

        if (this.steuernAbsolut.isZero()) {
            return income.multipliedBy(this.steuersatzProzent);
        }

        if (this.steuersatzProzent.isZero()) {
            return this.steuernAbsolut;
        }
        return income.multipliedBy(this.steuersatzProzent);
    }

    public toString = (): string => {
        return `Steuersatz UntereGrenze: ${this.steuerbaresEinkommenLower} ObereGrenze: ${this.steuerbaresEinkommenUpper} SteuernAbsolut: ${this.steuernAbsolut} Prozent: ${this.steuersatzProzent}`;
    }
}

export function downloadTarifsForCanton(canton: string): Promise<string[]> {
    const path = "/taxdata/tar24" + canton.toLowerCase() + ".txt";
    return fetch(path)
        .then(response => {
            return response.text()
        }).then(allLinesString => {
            return allLinesString.split("\n");
        });
}

export interface SteuerInput {
    isMarried: boolean,
    countKids: number,
    isChurchMember: boolean,
    income: number,
}

export function calculateTax(props: SteuerInput, cantonTaxes?: CantonTaxes) {
    const tarifcode = buildTarifcode(props.isMarried, props.countKids, props.isChurchMember);

    let incomePerMonth = new BigNumber(props.income).dividedBy(12);

    if (cantonTaxes) {
        let taxRate = cantonTaxes.getTaxRate(tarifcode, incomePerMonth.toNumber());
        if (taxRate) {
            console.log("taxRate gefunden!")
            return {betrag: taxRate.getSteuer(incomePerMonth), prozent: taxRate.steuersatzProzent};
        } else {
            console.log("kein taxRate gefunden!")
        }
    }

    console.log("kein Steuersatz gefunden!")
    return {prozent: new BigNumber(0), betrag: new BigNumber(0)};
}

export const Quellensteuer = (props: QuellensteuerProps): JSX.Element => {
    const [tax, setTax] = useState<Steuerergebnis>({prozent: new BigNumber(0), betrag: new BigNumber(0)});
    const [cantonTaxes, setCantonTaxes] = useState<CantonTaxes>()

    useEffect(() => {
        downloadTarifsForCanton(props.canton)
            .then(lines => {
                setCantonTaxes(new CantonTaxes(lines));
            })
            .catch(error => {
                console.log("request hat nicht geklappt");
            });

    }, [props.canton]);

    const {t} = useTranslation();

    // const { setAlert } = useContext(AlertContext);
    useEffect(() => {
        setTax(calculateTax(props, cantonTaxes));
    }, [props.isChurchMember, props.canton, props.income, props.countKids, props.isMarried, cantonTaxes]);

    props.setResult(tax.betrag.toNumber());
    return (
        <tr>
            <td>{t('withholding_tax')}</td>
            <td>{new Intl.NumberFormat('de-CH', {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2
            }).format(tax.prozent.toNumber())}</td>
            <td>{new Intl.NumberFormat('de-CH', {
                style: 'currency',
                currency: 'CHF'
            }).format(tax.betrag.toNumber())}</td>
        </tr>
    );
}

function buildTarifcode(isVerheiratet: boolean, countKids: number, isChurchMember: boolean) {
    var tarifcode = "";
    tarifcode = tarifcode + (isVerheiratet ? "B" : "A");
    tarifcode = tarifcode + countKids;
    tarifcode = tarifcode + (isChurchMember ? "Y" : "N");
    return tarifcode;
}
