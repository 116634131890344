import {Bar} from "react-chartjs-2";
import React, {useEffect, useState} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Cantons} from "./Cantons";
import {calculateTax, downloadTarifsForCanton} from "./abgaben/Quellensteuer";
import {useTranslation} from "react-i18next";
import BigNumber from "bignumber.js";
import {CantonTaxes} from "./abgaben/CantonTaxes";
import {Box, LoadingOverlay} from "@mantine/core";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export interface DiagrammProps {
    isMarried: boolean,
    countKids: number,
    isChurchMember: boolean,
    income: number,
}

interface steuerOutput {
    canton: string,
    tax: BigNumber,
}

interface Tuple {
    canton: string,
    tarifData: string[],
}

export const Diagramm = (props: DiagrammProps): JSX.Element => {

    const [diagramData, setDiagramData] = useState<steuerOutput[]>();

    const {t} = useTranslation();

    const [tarifsByCantons, setTarifsByCantons] = useState<Map<string, CantonTaxes>>(new Map());

    // load tarifs for all cantons
    useEffect(() => {
        let map1 = Object.keys(Cantons).map(async (canton) => {
            let tarifData = await downloadTarifsForCanton(canton.toLowerCase());
            return {canton, tarifData} as Tuple;
        });

        let promise1 = Promise.all(map1);

        promise1.then(all => {
            let map = new Map(all.map(it2 => [it2.canton, new CantonTaxes(it2.tarifData)]));


            setTarifsByCantons(map);
        })
    }, [])

    useEffect(() => {

        let results = Object.keys(Cantons).map(canton => {

            let cantonTaxes = tarifsByCantons.get(canton);
            if (cantonTaxes) {
                return {
                    canton: canton,
                    tax: calculateTax({
                        income: props.income,
                        isChurchMember: props.isChurchMember,
                        countKids: props.countKids,
                        isMarried: props.isMarried
                    }, cantonTaxes).betrag
                }
            } else {
                return {
                    canton: canton,
                    tax: new BigNumber(0),
                }
            }
        })

        setDiagramData(results);


    }, [props.isMarried, props.countKids, props.isChurchMember, props.income, tarifsByCantons]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: false, // TODO edit heading below and toggle this boolean
                text: 'Chart.js Bar Chart',
            },
        },
    };
    const data = {
        labels: diagramData?.map(it => {
            const indexOfCanton = Object.keys(Cantons).indexOf(it.canton);
            return Object.values(Cantons)[indexOfCanton]
        }),
        datasets: [
            {
                label: t('withholding_tax'),
                data: diagramData?.map(it => it.tax.toNumber()),
                backgroundColor: 'rgba(60, 60, 60, 0.5)',
            },
        ],
    };

    const showLoadingSpinner = !(tarifsByCantons.size > 0);

    return (
        <Box pos={"relative"}>
            <LoadingOverlay visible={showLoadingSpinner}/>
            <Bar
                options={options}
                data={data}/>
        </Box>

    );
}