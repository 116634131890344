import BigNumber from "bignumber.js";

export interface AHVProps {
	income: number,
	setResult: (a: number) => void
}

const ahvMultiplier = new BigNumber('0.053');

export const AHV = (props: AHVProps): JSX.Element => {
	const incomePerMonth = new BigNumber(props.income).dividedBy(12);
	const ahv = incomePerMonth.multipliedBy(ahvMultiplier)
	props.setResult(ahv.toNumber());
	return (
		<tr>
			<td>AHV</td>
			<td>{new Intl.NumberFormat('de-CH', { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(ahvMultiplier.toNumber())}</td>
			<td>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(ahv.toNumber())}</td>
		</tr>
	);
}