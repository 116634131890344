import React, {forwardRef, useEffect, useState} from 'react';
import './App.css';

// @ts-ignore
import { cantons as cantonicons } from "ch-canton-symbols/symbols"
import {Quellensteuer} from "./abgaben/Quellensteuer";
import {BVG} from "./abgaben/BVG";
import {AHV} from "./abgaben/AHV";
import {ALV1} from "./abgaben/ALV1";
import {
    MantineProvider,
    NumberInput,
    Select,
    Space,
    Stack,
    Radio,
    Table,
    Container,
    AppShell,
    SimpleGrid,
    Header,
    ColorScheme,
    ColorSchemeProvider,
    Button, Group
} from "@mantine/core";
import BigNumber from "bignumber.js";
import {AfterTax} from "./abgaben/AfterTax";
import {IconMoonStars} from "@tabler/icons";
import {useTranslation} from "react-i18next";
import { Diagramm } from './Diagramm';


const defaultAge = 30;
const defaultCountKids = 0;
const defaultIncome = 80_000;

function getInitialColorScheme() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return 'dark'
    }
    return 'light';
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    value: string;
    label: string;
}

// TODO do not use dangerouslySetInnerHTML
const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ value, label, ...others }: ItemProps, ref) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <div dangerouslySetInnerHTML={{ __html: cantonicons[value.toLowerCase()] }} />
                <p>{value}</p>
                <p>{label}</p>
            </Group>
        </div>
    )
);

function App() {
    const [income, setIncome] = useState(80_000);
    const [isVerheiratet, setIsVerheiratet] = useState(false);
    const [isKirche, setIsKirche] = useState(false);
    const [age, setAge] = useState<number>(defaultAge);
    const [canton, setCanton] = useState<string | null>("AG");
    const [countKids, setCountKids] = useState(0);

    const [ahv, setAhv] = useState(0);
    const [alv, setAlv] = useState(0);
    const [lohnsteuer, setLohnsteuer] = useState(0);
    const [bvg, setBvg] = useState(0);

    // print form props
    useEffect(() => {
        console.log("Aktuelle Werte: ")
        console.log("income: " + income)
        console.log("isVerheiratet: " + isVerheiratet);
        console.log("isKirche: " + isKirche);
        console.log("age: " + age);
        console.log("canton: " + canton);
        console.log("countKids: " + countKids);
    }, [income, isVerheiratet, isKirche, age, canton, countKids])

    const [colorScheme, setColorScheme] = useState<ColorScheme>(getInitialColorScheme());
    const toggleColorScheme = (value?: ColorScheme) =>
        setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

    const { t, i18n } = useTranslation();

    return (<>
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <MantineProvider withGlobalStyles withNormalizeCSS theme={{colorScheme}}>
                <AppShell padding="md"
                          header={<Header height={60} p="xs">
                              <div>
                                  <Button style={{float: "left"}} leftIcon={<IconMoonStars/>} onClick={() => { toggleColorScheme(); }} >{t('toggle_darkmode')}</Button>
                                  <Select style={{float: "right", display: "flex", alignItems: "center"}} label={t('language')} onChange={(value) => {
                                      if (value) i18n.changeLanguage(value)
                                  }}
                                          defaultValue={i18n.language}
                                          data={[
                                      {value: "de", label: t('german')?? "fallback german" },
                                      {value: "en", label: t('english')?? "fallback english"},
                                  ]}>
                                  </Select>
                              </div>
                </Header>}>
                    <Container size="md" style={{paddingTop: 40, paddingBottom: 40}}>
                        <SimpleGrid cols={2}>
                            <Stack>
                                <NumberInput label={t('yearly_income')} defaultValue={defaultIncome} min={10_000}
                                             step={1000}
                                             onChange={element => element ? setIncome(element!!) : setIncome(defaultIncome)}/>
                                <NumberInput label={t('age')} defaultValue={defaultAge} min={18} max={99}
                                             onChange={element => element ? setAge(element!!) : setAge(defaultAge)}/>
                                <NumberInput label={t('count_children')} defaultValue={defaultCountKids} min={0} max={9}
                                             onChange={element => element ? setCountKids(element!!) : setCountKids(defaultCountKids)}/>
                                <Select label={t('canton')} defaultValue={"AG"}
                                        searchable={true}
                                        itemComponent={SelectItem}
                                        data={[
                                            {value: "AG", label: "Aargau"},
                                            {value: "AI", label: "Appenzell Innerrhoden"},
                                            {value: "AR", label: "Appenzell Ausserrhoden"},
                                            {value: "BE", label: "Bern"},
                                            {value: "BL", label: "Basel-Land"},
                                            {value: "BS", label: "Basel-Stadt"},
                                            {value: "FR", label: "Freiburg"},
                                            {value: "GE", label: "Genf"},
                                            {value: "GL", label: "Glarus"},
                                            {value: "GR", label: "Graubünden"},
                                            {value: "JU", label: "Jura"},
                                            {value: "LU", label: "Luzern"},
                                            {value: "NE", label: "Neuenburg"},
                                            {value: "NW", label: "Nidwalden"},
                                            {value: "OW", label: "Obwalden"},
                                            {value: "SG", label: "St. Gallen"},
                                            {value: "SH", label: "Schaffhausen"},
                                            {value: "SO", label: "Solothurn"},
                                            {value: "SZ", label: "Schwyz"},
                                            {value: "TG", label: "Thurgau"},
                                            {value: "TI", label: "Tessin"},
                                            {value: "UR", label: "Uri"},
                                            {value: "VD", label: "Waadt"},
                                            {value: "VS", label: "Wallis"},
                                            {value: "ZG", label: "Zug"},
                                            {value: "ZH", label: "Zürich"},
                                        ]}
                                        onChange={setCanton}>
                                </Select>

                                <Radio.Group
                                    label={t('church_member')}
                                    withAsterisk
                                    defaultValue={"false"}
                                    onChange={element => element === "true" ? setIsKirche(true) : setIsKirche(false)}
                                >
                                    <Radio value="true" label={t('yes')}/>
                                    <Radio value="false" label={t('no')}/>
                                </Radio.Group>

                                <Radio.Group
                                    label={t('married')}
                                    withAsterisk
                                    defaultValue={"false"}
                                    onChange={element => element === "true" ? setIsVerheiratet(true) : setIsVerheiratet(false)}
                                >
                                    <Radio value="true" label={t('yes')}/>
                                    <Radio value="false" label={t('no')}/>
                                </Radio.Group>
                            </Stack>
                            <Stack>
                                <Table striped>
                                    <thead>
                                    <tr>
                                        <th>{t('deduction')}</th>
                                        <th>{t('percentage')}</th>
                                        <th>{t('amount')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{t('gross_monthly_income')}</td>
                                        <td></td>
                                        <td>{new Intl.NumberFormat('de-CH', {
                                            style: 'currency',
                                            currency: 'CHF'
                                        }).format(new BigNumber(income).dividedBy(12).toNumber())}</td>
                                    </tr>
                                    <Quellensteuer income={income} canton={canton ? canton : ""}
                                                   isMarried={isVerheiratet} countKids={countKids}
                                                   isChurchMember={isKirche} setResult={setLohnsteuer}/>
                                    <AHV income={income} setResult={setAhv}/>
                                    <ALV1 income={income} setResult={setAlv}/>
                                    <BVG income={income} age={age ? age : 0} setResult={setBvg}/>

                                    </tbody>
                                    <tfoot>
                                    <AfterTax income={income} ahv={ahv} bvg={bvg} alv={alv} lohnsteuer={lohnsteuer}/>
                                    </tfoot>
                                </Table>
                            </Stack>
                        </SimpleGrid>
                        <Diagramm income={income}
                                  isMarried={isVerheiratet}
                                  countKids={countKids}
                                  isChurchMember={isKirche}/>
                        <Space h="md"/>

                    </Container>
                </AppShell>
            </MantineProvider>
        </ColorSchemeProvider>
    </>);
}

export default App;
