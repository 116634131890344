import BTree from "sorted-btree";
import {Steuersatz} from "./Quellensteuer";
import BigNumber from "bignumber.js";


export class CantonTaxes {

    // Map<tarifcode, Btree<grossIncome, Steuersatz>>
    private treesByTarifCode: Map<String, BTree<number, Steuersatz>> = new Map();


    constructor(lines: string[]) {

        let taxRates = lines.map(line => this.matchLine(line));

        taxRates.forEach(taxRate => {

            if(taxRate) {
                let treeForTarifCode = this.treesByTarifCode.get(taxRate[0]);
                if (treeForTarifCode) {
                    treeForTarifCode.set(taxRate[1].steuerbaresEinkommenLower.toNumber(), taxRate[1]);
                } else {
                    this.treesByTarifCode.set(taxRate[0], new BTree<number, Steuersatz>([[taxRate[1].steuerbaresEinkommenLower.toNumber(), taxRate[1]]]))
                }

            }
        })

        this.treesByTarifCode.forEach(treeSiz => {
            treeSiz.freeze();
        })
    }

    getTaxRate(tarifCode: string, grossIncome: number): Steuersatz {
        let newVar: Steuersatz | undefined = this.treesByTarifCode.get(tarifCode)?.getPairOrNextLower(grossIncome)!![1];
        if (!newVar) {
            console.log("kein Steuersatz gefunden, versuche Tarifcode zu toggeln!")
            return this.treesByTarifCode.get(this.toggleTarifCode(tarifCode))!!.getPairOrNextLower(grossIncome)!![1];
        }
        return newVar;
    }

    toggleTarifCode(tarifCode: String) {
        if(tarifCode.at(2) === "Y") {
            return tarifCode.replace("Y", "N");
        } else {
            return tarifCode.replace("N", "Y");
        }
    }

    matchLine(line: string): [string, Steuersatz] | null {
        const regexp = new RegExp('^0601([A-Z]{2})([AB][0-9][YN])\\s{7}[0-9]{8}([0-9]{7})([0-9]{2})([0-9]{7})([0-9]{2})\\s([0-9]{2})([0-9]{7})([0-9]{2})([0-9]{3})([0-9]{2})\\s\\s\\s');

        let regExpExecArray = regexp.exec(line);
        if (regExpExecArray === null) {
            return null;
        }

        let canton = regExpExecArray[1];
        let tarifcode = regExpExecArray[2];
        let steuerbaresEinkommenFranken = regExpExecArray[3];
        let steuerbaresEinkommenRappen = regExpExecArray[4];
        let tarifschrittFranken = regExpExecArray[5];
        let tarifschrittRappen = regExpExecArray[6];
        let countChildren = regExpExecArray[7];
        let steuernFranken = regExpExecArray[8];
        let steuernRappen = regExpExecArray[9];
        let prozentSteuern = regExpExecArray[10];
        let prozentSteuernKommastellen = regExpExecArray[11];

        const steuerbaresEinkommen = new BigNumber(steuerbaresEinkommenFranken + '.' + steuerbaresEinkommenRappen);
        const tarifschritt = new BigNumber(tarifschrittFranken + '.' + tarifschrittRappen);
        const steuernAbsolut = new BigNumber(steuernFranken + '.' + steuernRappen);
        const steuersatz = new BigNumber(prozentSteuern + '.' + prozentSteuernKommastellen).dividedBy(100);

        return [tarifcode, new Steuersatz(steuerbaresEinkommen, tarifschritt, steuersatz, steuernAbsolut)];
    }

}