

export enum Cantons {
  AG = "Aargau",
  AI = "Appenzell Innerrhoden",
  AR = "Appenzell Ausserrhoden",
  BE = "Bern",
  BL = "Basel-Land",
  BS = "Basel-Stadt",
  FR = "Freiburg",
  GE = "Genf",
  GL = "Glarus",
  GR = "Graubünden",
  JU = "Jura",
  LU = "Luzern",
  NE = "Neuenburg",
  NW = "Nidwalden",
  OW = "Obwalden",
  SG = "St. Gallen",
  SH = "Schaffhausen",
  SO = "Solothurn",
  SZ = "Schwyz",
  TG = "Thurgau",
  TI = "Tessin",
  UR = "Uri",
  VD = "Waadt",
  VS = "Wallis",
  ZG = "Zug",
  ZH = "Zürich"
}