import BigNumber from "bignumber.js";
import {Group, ThemeIcon, Tooltip} from "@mantine/core";
import { IconInfoSquare } from '@tabler/icons';

const MAX_VERSICHERTER_BVG_LOHN = 62_475;
const KOORDINATIONSABZUG = 25_725;

export interface BVGProps {
	income: number,
	age: number,
	setResult: (a: number) => void
}

export const BVG = (props: BVGProps): JSX.Element => {
	const percentage = pillar2Percentage(props.age);
	const koordinierterLohn = computeKoordinierterLohn(props.income);
	const bvgBeitraege = koordinierterLohn.dividedBy(12).multipliedBy(percentage);
	props.setResult(bvgBeitraege.toNumber());
	return (
			<tr>
				<td>
					<Group>
						BVG
						<Tooltip label={"Hierbei handelt es sich um den obligatorischen Betrag. Je nach Pensionskasse kann dieser höher sein! Die Pensionskasse wird vom Arbeitgeber gewählt."}>
							<ThemeIcon variant="default">
								<IconInfoSquare size={20} />
							</ThemeIcon>
						</Tooltip>
					</Group>
				</td>
				<td/>
				<td>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(bvgBeitraege.toNumber())}</td>
			</tr>
	);
}

function pillar2Percentage(age: number) : BigNumber {
	switch (true) {
		case (age < 25):
			return new BigNumber(0);
		case (age < 35):
			return new BigNumber('0.035');
		case (age < 45):
			return new BigNumber('0.05');
		case (age < 55):
			return new BigNumber('0.075');
		case (age >= 55):
			return new BigNumber('0.09');
		default:
			return new BigNumber(0);
	}
}

function computeKoordinierterLohn(income: number) {
	let koordinierterLohn = new BigNumber(income).minus(KOORDINATIONSABZUG);
	if (koordinierterLohn.isLessThanOrEqualTo(0)) {
		return new BigNumber(0);
	}
	if (koordinierterLohn.isGreaterThan(MAX_VERSICHERTER_BVG_LOHN)) {
		return new BigNumber(MAX_VERSICHERTER_BVG_LOHN);
	}
	return koordinierterLohn;
}