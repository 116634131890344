import BigNumber from "bignumber.js";
import React from "react";
import {useTranslation} from "react-i18next";

export interface AfterTaxProps {
    income: number,
    ahv: number,
    alv: number,
    bvg: number,
    lohnsteuer: number
}

export const AfterTax = (props: AfterTaxProps): JSX.Element => {
    const incomePerMonth = new BigNumber(props.income).dividedBy(12);
    const {t} = useTranslation();

    let abzuege = incomePerMonth.minus(props.ahv)
        .minus(props.alv)
        .minus(props.bvg)
        .minus(props.lohnsteuer);

    return (
        <tr>
            <th>{t('net_monthly_income')}</th>
            <th></th>
            <th>{new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(abzuege.toNumber())}</th>
        </tr>
    )
}