import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18next from "i18next";
import {initReactI18next} from "react-i18next";


function getBrowsersLanguage() {
    return window.navigator.language.split('-')[0]
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const de = {
    withholding_tax: 'Quellensteuer',
    bvg: "BVG",
    retirement: "AHV",
    toggle_darkmode: 'Nachtmodus ein/aus',
    language: 'Sprache',
    german: 'deutsch',
    english: 'englisch',
    yes: 'Ja',
    no: 'Nein',
    gross_monthly_income: 'Bruttolohn pro Monat',
    net_monthly_income: 'Nettolohn pro Monat',
    yearly_income: 'Jahresbruttolohn',
    age: 'Alter',
    count_children: 'Anzahl Kinder',
    canton: 'Kanton',
    deduction: 'Abzug',
    percentage: 'Prozentsatz',
    amount: 'Betrag',
    married: 'verheiratet',
    church_member: 'Kirchenmitglied'
};

const en = {
    withholding_tax: 'withholding tax',
    bvg: "BVG (EN)",
    retirement: "AHV (EN)",
    toggle_darkmode: 'toggle darkmode',
    language: 'language',
    german: 'german',
    english: 'english',
    yes: 'Yes',
    no: 'No',
    gross_monthly_income: 'monthly gross income',
    net_monthly_income: 'monthly net income',
    yearly_income: 'yearly gross income',
    age: 'age',
    count_children: 'count children',
    canton: 'canton',
    deduction: 'deduction',
    percentage: 'percentage',
    amount: 'amount',
    married: 'married',
    church_member: 'church member'
};
i18next.use(initReactI18next).init({
    lng: getBrowsersLanguage(),
    fallbackLng: 'en',
    resources: {
        en: {
            translation: en,
        },
        de: {
            translation: de,
        },
    },
});





root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
